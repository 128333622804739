import axios from 'axios'
import { mutate } from 'swr';

let groupId: number | undefined;

export function fetcherWithUserGroup(url: string) {
    return axios.get(url, {
        headers: { 'x-group': groupId }
    }).then(res => res.data);
};

let initialized = false;
export function setInitialGroup(id: number) {
    if (initialized) return;
    groupId = id;
    initialized = true;
}

export function setGroup(id: number | undefined) {
    groupId = id;
    mutate(() => true); // Revalidate all APIs
}
