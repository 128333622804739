import { AlertIcon, Box, Button, Center, Alert as ChakraAlert, Container, Flex } from "@chakra-ui/react";
import { signIn, useSession } from "next-auth/react";
import type { PropsWithChildren } from "react";
import { FcGoogle } from "react-icons/fc";
import { NavBar } from "./Navbar";
import { Spinner } from "./Spinner";

export function Page({ children }: PropsWithChildren<{}>) {
  const { data: session, status } = useSession();

  if (status === "loading") return <Spinner />;

  const isMessenger = ['FB_IAB', 'FB4A', 'FBAV', 'FBIOS'].some(userAgent => navigator.userAgent.includes(userAgent));

  if (!session) {
    return (
      <Center height={"100vh"} flexDirection="column" px={4}>
        {
          isMessenger
            ? <ChakraAlert status="warning" rounded={10} mb={4}>
              <AlertIcon />
              Facebook Messengeri sees sisselogimisel võib tekkida viga.
              Ava see lehekülg oma telefoni brauseris (Chrome, Safari).
            </ChakraAlert>
            : undefined
        }

        <Button w="full" maxW="md" variant="outline" onClick={() => signIn("google")} leftIcon={<FcGoogle />}>
          <Center>Logi sisse Google kontoga</Center>
        </Button>
      </Center >

    );
  }

  return (
    <>
      <NavBar />
      <Box py={4}>{children}</Box>
    </>
  );
}
