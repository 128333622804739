import { ChevronDownIcon, MoonIcon, SunIcon } from "@chakra-ui/icons";
import {
  Avatar,
  Box,
  Button,
  Flex,
  HStack,
  IconButton,
  Link as ChakraLink,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  useColorMode,
  useColorModeValue,
  MenuDivider,
} from "@chakra-ui/react";
import { signOut, useSession } from "next-auth/react";
import NextLink from "next/link";
import { useRouter } from "next/router";
import { PropsWithChildren, useRef } from "react";
import { InfoButtonWithModal } from "./InfoButtonWithModal";
import { setGroup, setInitialGroup } from "lib/swr-fetcher";

export function NavBar() {
  const { data: session } = useSession();

  const { colorMode, toggleColorMode } = useColorMode();

  // TODO: Figure out better way of setting ID of first available group
  setInitialGroup(session!.groups.at(0)!.id);

  return (
    <Box bg={useColorModeValue("gray.100", "gray.900")} px={2} boxShadow="md" position="sticky" top={0} zIndex={1}>
      <Flex h={12} justifyContent={"space-between"}>
        <HStack>
          <NavBarLink href="/matches">Mängud</NavBarLink>
          <NavBarLink href="/standings">Üldseis</NavBarLink>
        </HStack>

        <Flex alignItems={"center"}>
          <InfoButtonWithModal />
          <IconButton mr={4} rounded={"full"} aria-label="" icon={colorMode === "light" ? <MoonIcon /> : <SunIcon />} onClick={() => toggleColorMode()} />
          <Menu initialFocusRef={useRef(null)}>
            <MenuButton as={Button} rounded={"full"} variant={"link"} rightIcon={<ChevronDownIcon />}>
              <Avatar size={"sm"} src={session?.user.avatarUrl} name={session?.user.name} />
            </MenuButton>
            <MenuList>
              {session?.groups.map(group => <MenuItem key={group.id} onClick={() => setGroup(group.id)}>{session?.groups.length === 1 ? 'Minu grupp' : group.name}</MenuItem>)}
              <MenuItem onClick={() => setGroup(undefined)}>Kõik liikmed</MenuItem>
              <MenuDivider />
              <MenuItem onClick={() => signOut()}>Logi välja</MenuItem>
            </MenuList>
          </Menu>
        </Flex>
      </Flex>
    </Box>
  );
}

const NavBarLink = ({ children, href }: PropsWithChildren<{ href: string }>) => {
  const router = useRouter();
  const activeLinkBg = useColorModeValue("gray.300", "gray.600");
  const isActive = router.pathname === href;

  return (
    <NextLink href={href} passHref legacyBehavior={true}>
      <ChakraLink
        px={2}
        py={1}
        rounded={"lg"}
        bg={isActive ? activeLinkBg : ""}
        _hover={{
          bg: useColorModeValue("gray.200", "gray.700"),
        }}
      >
        {children}
      </ChakraLink>
    </NextLink>
  );
};
