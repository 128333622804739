import { QuestionIcon } from "@chakra-ui/icons";
import { Modal, ModalBody, ModalCloseButton, ModalContent, ModalHeader, ModalOverlay } from "@chakra-ui/modal";
import {
  Badge,
  Box,
  Divider,
  Heading,
  HStack,
  IconButton,
  Link,
  ListItem,
  Table,
  TableContainer,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
  UnorderedList,
  useDisclosure,
} from "@chakra-ui/react";
import { PointsBadge } from "./PointsBadge";
import React from "react";

export function InfoButtonWithModal() {
  const { isOpen, onOpen, onClose } = useDisclosure();

  return (
    <>
      <IconButton mr={4} rounded={"full"} aria-label="" icon={<QuestionIcon />} onClick={onOpen} />

      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Kuidas antakse punkte?</ModalHeader>
          <ModalCloseButton />
          <ModalBody mb={4}>
            <Heading size="sm" mb={2}>
              Punktiarvestus alagrupimängude ajal
            </Heading>
            <Box mb={2}>
              <PointsBadge points={1} suffix={true} /> võitjameeskonna või viigi äraarvamise eest
              <Box>+</Box>
              <PointsBadge points={1} suffix={true} /> õige väravate vahe äraarvamise eest
              <Box>+</Box>
              <PointsBadge points={1} suffix={true} /> täpse lõppskoori äraarvamise eest
            </Box>
            <Divider />
            <Box mt={2}>Näited:</Box>
            <TableContainer>
              <Table size="sm">
                <Thead>
                  <Tr>
                    <Th>Ennustus</Th>
                    <Th>Tegelik</Th>
                    <Th>Punktid</Th>
                  </Tr>
                </Thead>
                <Tbody>
                  <Tr>
                    <Td>
                      <Badge variant="outline">1-0</Badge>
                    </Td>
                    <Td>
                      <Badge variant="solid">0-1</Badge>
                    </Td>
                    <Td>
                      <HStack>
                        <PointsBadge points={0} /> <Box>(0+0+0)</Box>
                      </HStack>
                    </Td>
                  </Tr>
                  <Tr>
                    <Td>
                      <Badge variant="outline">1-0</Badge>
                    </Td>
                    <Td>
                      <Badge variant="solid">2-0</Badge>
                    </Td>
                    <Td>
                      <HStack>
                        <PointsBadge points={1} /> <Box>(1+0+0)</Box>
                      </HStack>
                    </Td>
                  </Tr>
                  <Tr>
                    <Td>
                      <Badge variant="outline">1-0</Badge>
                    </Td>
                    <Td>
                      <Badge variant="solid">2-1</Badge>
                    </Td>
                    <Td>
                      <HStack>
                        <PointsBadge points={2} /> <Box>(1+1+0)</Box>
                      </HStack>
                    </Td>
                  </Tr>
                  <Tr>
                    <Td>
                      <Badge variant="outline">2-2</Badge>
                    </Td>
                    <Td>
                      <Badge variant="solid">0-0</Badge>
                    </Td>
                    <Td>
                      <HStack>
                        <PointsBadge points={2} /> <Box>(1+1+0)</Box>
                      </HStack>
                    </Td>
                  </Tr>
                  <Tr>
                    <Td>
                      <Badge variant="outline">1-0</Badge>
                    </Td>
                    <Td>
                      <Badge variant="solid">1-0</Badge>
                    </Td>
                    <Td>
                      <HStack>
                        <PointsBadge points={3} exactPrediction={true} /> <Box>(1+1+1)</Box>
                      </HStack>
                    </Td>
                  </Tr>
                </Tbody>
              </Table>
            </TableContainer>

            <Heading size="sm" mt={6} mb={2}>
              Punktiarvestus alates kaheksandikfinaalist
            </Heading>
            <Box mb={2}>
              Kuni <PointsBadge points={3} suffix={true} /> skoori äraarvamise eest (nagu alagrupimängudes). Kui mäng läheb penaltiseeriani, siis arvestame
              skoori lisaaja lõpu seisuga.
            </Box>
            <Box mb={2}>Need, kes arvasid õigesti võitjameeskonna või viigi, saavad lisapunkte mängu lõppaja äraarvamise eest:</Box>
            <Box mt={2} mb={2}>
              <UnorderedList>
                <ListItem>
                  <PointsBadge points={1} suffix={true} /> mäng lõppes normaalajaga
                  <Box>või</Box>
                </ListItem>
                <ListItem>
                  <PointsBadge points={2} suffix={true} /> mäng lõppes lisaajaga
                  <Box>või</Box>
                </ListItem>
                <ListItem>
                  <PointsBadge points={3} suffix={true} /> mäng lõppes penaltitega
                </ListItem>
              </UnorderedList>
            </Box>
            <Divider />
            <Box mt={2}>Näited:</Box>
            <TableContainer>
              <Table size="sm">
                <Thead>
                  <Tr>
                    <Th>Ennustus</Th>
                    <Th>Tegelik</Th>
                    <Th>Punktid</Th>
                  </Tr>
                </Thead>
                <Tbody>
                  <Tr>
                    <Td>
                      <Badge variant="outline">1-0 N</Badge>
                    </Td>
                    <Td>
                      <Badge variant="solid">0-1 N</Badge>
                    </Td>
                    <Td>
                      <HStack>
                        <PointsBadge points={0} /> <Box>(0+0+0+0)</Box>
                      </HStack>
                    </Td>
                  </Tr>
                  <Tr>
                    <Td>
                      <Badge variant="outline">1-0 N</Badge>
                    </Td>
                    <Td>
                      <Badge variant="solid">3-1 N</Badge>
                    </Td>
                    <Td>
                      <HStack>
                        <PointsBadge points={2} /> <Box>(1+0+0+1)</Box>
                      </HStack>
                    </Td>
                  </Tr>
                  <Tr>
                    <Td>
                      <Badge variant="outline">1-0 L</Badge>
                    </Td>
                    <Td>
                      <Badge variant="solid">4-1 L</Badge>
                    </Td>
                    <Td>
                      <HStack>
                        <PointsBadge points={3} /> <Box>(1+0+0+2)</Box>
                      </HStack>
                    </Td>
                  </Tr>
                  <Tr>
                    <Td>
                      <Badge variant="outline">1-0 N</Badge>
                    </Td>
                    <Td>
                      <Badge variant="solid">1-0 N</Badge>
                    </Td>
                    <Td>
                      <HStack>
                        <PointsBadge points={4} exactPrediction={true} /> <Box>(1+1+1+1)</Box>
                      </HStack>
                    </Td>
                  </Tr>
                  <Tr>
                    <Td>
                      <Badge variant="outline">1-0 L</Badge>
                    </Td>
                    <Td>
                      <Badge variant="solid">1-0 L</Badge>
                    </Td>
                    <Td>
                      <HStack>
                        <PointsBadge points={5} exactPrediction={true} /> <Box>(1+1+1+2)</Box>
                      </HStack>
                    </Td>
                  </Tr>
                  <Tr>
                    <Td>
                      <Badge variant="outline">1-1 P</Badge>
                    </Td>
                    <Td>
                      <Badge variant="solid">3-3 P</Badge>
                    </Td>
                    <Td>
                      <HStack>
                        <PointsBadge points={5} /> <Box>(1+1+0+3)</Box>
                      </HStack>
                    </Td>
                  </Tr>
                  <Tr>
                    <Td>
                      <Badge variant="outline">1-1 P</Badge>
                    </Td>
                    <Td>
                      <Badge variant="solid">1-1 P</Badge>
                    </Td>
                    <Td>
                      <HStack>
                        <PointsBadge points={6} exactPrediction={true} /> <Box>(1+1+1+3)</Box>§
                      </HStack>
                    </Td>
                  </Tr>
                </Tbody>
              </Table>
            </TableContainer>

            <Heading size="sm" mt={6} mb={2}>
              Tagasiside
            </Heading>
            <Box mb={2}>
              <Box>Mõtted, ettepanekud siia:</Box>
              <Link color='teal.500' target="_blank" href="https://forms.gle/PQqUmnm7uCZiKdvV9">
                https://forms.gle/PQqUmnm7uCZiKdvV9
              </Link>
            </Box>

          </ModalBody>
        </ModalContent>
      </Modal>
    </>
  );
}
