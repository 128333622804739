import { Page } from "components/Page";
import { SessionProvider } from "next-auth/react";
import type { AppProps } from "next/app";
import { ChakraProvider } from "@chakra-ui/react";
import "../../styles/globals.css";
import { SWRConfig } from "swr";
import axios from "axios";
import Head from "next/head";
import { fetcherWithUserGroup } from "lib/swr-fetcher";

export default function App({ Component, pageProps }: AppProps) {
  return (
    <>
      <Head>
        <title>Euro 2024</title>
        <link rel="icon" type="image/svg+xml" href="/favicon.svg" />
        <meta property="og:title" content="UEFA Euro 2024 ennustusmäng" />
        <meta property="og:description" content="14.06 - 14.07, 24 riiki, 51 mängu" />
        <meta property="og:image" content="https://euro2024.hammertime.ee/euro2024.png" />
      </Head>

      <SessionProvider session={pageProps.session}>
        <SWRConfig value={{ fetcher: fetcherWithUserGroup }}>
          <ChakraProvider>
            <Page>
              <Component {...pageProps} />
            </Page>
          </ChakraProvider>
        </SWRConfig>
      </SessionProvider>
    </>
  );
}
