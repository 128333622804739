import { Center, useColorModeValue } from "@chakra-ui/react";
import { StageSpinner } from "react-spinners-kit";
import React from "react";

export function Spinner() {
  const spinnerColor = useColorModeValue("#2D3748", "#E2E8F0");
  return (
    <Center>
      <StageSpinner color={spinnerColor} />
    </Center>
  );
}
