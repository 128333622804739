import { StarIcon } from "@chakra-ui/icons";
import { Badge, Box, createIcon, Flex, Tooltip } from "@chakra-ui/react";

export function PointsBadge({
  points,
  suffix,
  exactPrediction,
  hadExactPrediction,
}: {
  points: number;
  suffix?: boolean;
  exactPrediction?: boolean;
  hadExactPrediction?: boolean;
}) {
  return (
    <Box display="inline-block">
      <Flex>
        <Badge colorScheme={points > 0 ? "green" : "red"} variant="solid">
          {points} {suffix ? (points === 1 ? "punkt" : "punkti") : null}
        </Badge>
        {exactPrediction ? <ExactPredictionMarker /> : hadExactPrediction ? <BadPredictionChangeMarker /> : null}
      </Flex>
    </Box>
  );
}

function ExactPredictionMarker() {
  return (
    <Tooltip label="Täpne ennustus, maksimumpunktid">
      <Box width="0" overflow="visible" transform="translate(-6px, -8px)">
        <StarIcon stroke="black" w={3} color="gold" filter="drop-shadow(0px 0px 1px #333)" />
      </Box>
    </Tooltip>
  );
}

function BadPredictionChangeMarker() {
  return (
    <Tooltip label="Ennustas täpselt, aga hiljem muutis ennustust">
      <Box width="0" overflow="visible" transform="translate(-6px, -8px)">
        <FrowningFaceIcon w={3} filter="drop-shadow(0px 0px 1px #333)" />
      </Box>
    </Tooltip>
  );
}

// Copy-pasted and slightly modified from https://www.svgrepo.com/show/13150/sad.svg
export const FrowningFaceIcon = createIcon({
  viewBox: "0 0 296 296",
  path: (
    <g>
      <path
        fill="gold"
        d="M270.996,123.998c0-11.334-1.363-22.348-3.907-32.9c-7.269-15.152-17.35-28.708-29.558-39.996
		c-22.391-13.376-48.766-20.666-76.772-19.645c-37.566,1.369-71.131,17.422-95.386,42.433l45.716-19.798l6.359,14.682L66.777,90.718
		l-5.418-12.51c-23.196,26.016-36.774,60.674-35.404,98.255c0.413,11.304,2.17,22.239,5.087,32.673
		c6.303,12.01,14.397,22.938,23.934,32.42c21.892,14.189,47.99,22.44,76.023,22.44C208.316,263.996,270.996,201.316,270.996,123.998
		z M184.904,54.092l50.672,21.944l-6.359,14.682l-50.672-21.944L184.904,54.092z M213.497,115.998c0,8.836-7.164,16-16,16
		s-16-7.164-16-16s7.164-16,16-16S213.497,107.162,213.497,115.998z M98.497,99.998c8.836,0,16,7.164,16,16s-7.164,16-16,16
		s-16-7.164-16-16S89.661,99.998,98.497,99.998z M78.384,209.508l-14.648-6.438c13.872-31.566,45.052-53.112,79.435-54.892
		c34.606-1.798,67.991,16.555,85.014,46.744c1.486,2.636,2.857,5.377,4.075,8.148l-14.648,6.436
		c-1.005-2.286-2.136-4.548-3.363-6.724c-14.066-24.946-41.666-40.108-70.25-38.626C115.599,165.627,89.845,183.428,78.384,209.508z
		"
      />
      <path
        fill="gold"
        d="M267.089,91.098c2.544,10.553,3.907,21.566,3.907,32.9c0,77.318-62.68,139.998-139.998,139.998
		c-28.032,0-54.131-8.251-76.023-22.44c23.88,23.744,56.766,38.44,93.023,38.44c72.784,0,131.998-59.214,131.998-131.998
		C279.996,127.636,275.358,108.337,267.089,91.098z"
      />
      <path
        fill="gold"
        d="M61.359,78.208l-0.941-2.172l4.956-2.146c24.255-25.011,57.819-41.063,95.386-42.433
		c28.006-1.021,54.381,6.268,76.772,19.645C213.985,29.328,182.521,16,147.998,16C75.214,16,16,75.214,16,147.998
		c0,22.049,5.442,42.849,15.042,61.138c-2.917-10.434-4.674-21.369-5.087-32.673C24.585,138.882,38.163,104.224,61.359,78.208z"
      />
      <path
        d="M147.998,0C66.392,0,0,66.392,0,147.998s66.392,147.998,147.998,147.998s147.998-66.392,147.998-147.998
      S229.605,0,147.998,0z M147.998,279.996c-36.257,0-69.143-14.696-93.023-38.44c-9.536-9.482-17.631-20.41-23.934-32.42
      C21.442,190.847,16,170.047,16,147.998C16,75.214,75.214,16,147.998,16c34.523,0,65.987,13.328,89.533,35.102
      c12.208,11.288,22.289,24.844,29.558,39.996c8.27,17.239,12.907,36.538,12.907,56.9
      C279.996,220.782,220.782,279.996,147.998,279.996z M92.132,276.394c0.24,0.105,0.48,0.212,0.722,0.316
      C92.612,276.605,92.372,276.499,92.132,276.394z"
      />
      <circle cx="98.497" cy="115.998" r="16" />
      <circle cx="197.497" cy="115.998" r="16" />

      <rect x="179.443" y="64.402" transform="matrix(-0.9176 -0.3974 0.3974 -0.9176 368.2772 221.1255)" width="55.218" height="15.999" />
      <polygon points="61.359,78.208 66.777,90.718 117.449,68.774 111.09,54.092 65.374,73.89 60.418,76.036 	" />
      <path
        d="M214.247,202.783c1.228,2.176,2.358,4.438,3.363,6.724l14.648-6.436c-1.218-2.771-2.589-5.513-4.075-8.148
		c-17.022-30.189-50.407-48.542-85.014-46.744c-34.383,1.779-65.563,23.325-79.435,54.892l14.648,6.438
		c11.461-26.08,37.215-43.881,65.613-45.351C172.581,162.675,200.181,177.837,214.247,202.783z"
      />
    </g>
  ),
});
